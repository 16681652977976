import css from './input-textarea.css.yml'
import { inputs } from '@/mixins/inputs/inputs'

export default {
  name: 'input-textarea',

  mixins: [inputs],

  data() {
    return {
      css: css,
    }
  },
}

import css from './modal.css.yml'

export default {
  name: 'modal',

  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    wrapperClasses() {
      let classes = [this.css.wrapper.base]
      if (this.visible) classes.push(this.css.wrapper.__visible)
      if (!this.visible) classes.push(this.css.wrapper.__hidden)
      return classes
    },

    overlayClasses() {
      let classes = [this.css.overlay.base]
      if (this.visible) classes.push(this.css.overlay.__visible)
      if (!this.visible) classes.push(this.css.overlay.__hidden)
      return classes
    },

    contentClasses() {
      let classes = [this.css.content.base]
      if (this.visible) classes.push(this.css.content.__visible)
      if (!this.visible) classes.push(this.css.content.__hidden)
      return classes
    },
  },

  methods: {
    close() {
      this.$emit('closed')
    },
  },

  data() {
    return {
      css: css,
    }
  },
}

import css from './input-text.css.yml'
import { inputs } from '@/mixins/inputs/inputs'

export default {
  name: 'input-text',

  mixins: [inputs],

  props: {
    type: {
      type: String,
      default: 'text',
      required: false,
    },
  },

  data() {
    return {
      css: css,
    }
  },
}

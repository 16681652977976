import css from './inputs.css.yml'
import Icon from '@/components/icon/icon.vue'

export const inputs = {
  components: { Icon },

  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
      required: false,
    },
  },

  computed: {
    id() {
      return `txt-${this.name}`
    },

    inputClasses() {
      let classes = [this.css.input, this.css.input_base]
      classes.push(this.css.input__icon_left)
      classes.push(
        this.value != '' ? this.css.input__filled : this.css.input__empty
      )
      return classes
    },

    containerClasses() {
      let classes = [this.css.container]
      classes.push(
        this.focused ? this.css.container__focused : this.css.container__blurred
      )
      return classes
    },
  },

  methods: {
    focus() {
      this.focused = true
    },

    blur() {
      this.focused = false
    },

    input(event) {
      this.value = event.target.value
    },
  },

  data() {
    return {
      css: css,
      value: '',
      focused: false,
    }
  },
}

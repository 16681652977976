import css from './input-label.css.yml'

export default {
  name: 'input-label',

  data() {
    return {
      css: css,
    }
  },
}

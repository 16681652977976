import css from './button.css.yml'

export default {
  name: 'btn',

  props: {
    href: {
      type: String,
      default: null,
      required: false,
    },
    type: {
      type: String,
      default: 'button',
      required: false,
    },
  },

  computed: {
    tagName() {
      return this.href ? 'router-link' : 'button'
    },

    to() {
      return this.href
    },

    buttonType() {
      return this.href ? null : this.type
    },
  },

  data() {
    return {
      css: css,
    }
  },
}

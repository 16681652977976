import axios from 'axios'
import css from './contact-form.css.yml'
import Btn from '@/components/button/button.vue'
import Icon from '@/components/icon/icon.vue'
import Modal from '@/components/modal/modal.vue'
import InputText from '@/components/input-text/input-text.vue'
import InputLabel from '@/components/input-label/input-label.vue'
import InputTextarea from '@/components/input-textarea/input-textarea.vue'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'contact-form',

  components: { Btn, InputLabel, InputText, InputTextarea, Icon, Modal },

  mixins: [analytics],

  methods: {
    submit() {
      this.errors = []
      if (!this.$refs.name.value) this.errors.push('Name is required.')
      if (!this.$refs.email.value) this.errors.push('Email is required.')
      if (!this.$refs.message.value) this.errors.push('Message is required.')
      if (this.errors.length == 0) {
        this.identifyUser(this.$refs.name.value, this.$refs.email.value)
        return this.postMessage()
      }
    },

    async postMessage() {
      try {
        await axios.post('/api/messages', {
          name: this.$refs.name.value,
          email: this.$refs.email.value,
          company: this.$refs.company.value,
          message: this.$refs.message.value,
          'env-name': process.env.NODE_ENV,
        })
        this.thanksVisible = true
      } catch (error) {
        this.errors = ['An unexpected error occurred.']
        console.error(error)
      }
    },

    closeThanksModal() {
      this.thanksVisible = false
    },
  },

  data() {
    return {
      css: css,
      thanksVisible: false,
      errors: [],
    }
  },
}
